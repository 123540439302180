import React, {useEffect, useState} from "react";
import {ChipInputWrapper} from "./styles";
import {Chip} from "@mui/material";

interface Props {
    values: string[];
    onValuesChanged: (vals: string[]) => void;
    style?: any;
}

const ChipInput: React.FC<Props> = (props: Props) => {
    const { values, onValuesChanged, style } = props;

    const [chips, setChips] = useState<string[]>(values);
    const [text, setText] = useState<string>('');

    useEffect(() => {
        setChips(values);
    }, [values]);

    return (<ChipInputWrapper style={style}>
        {
            chips.map(chip => <Chip label={chip}
                                    onDelete={() => {
                                        const newVals = chips.filter(item => item !== chip)
                                        setChips(newVals);
                                        onValuesChanged([...newVals]);
                                    }} />)
        }
        <input
            value={text}
            onChange={e => setText(e.target.value)}
            onKeyUp={event => { if(event.key === 'Enter') {
                if(text.length > 0) {
                    const newVals = [...chips, text];
                    setChips(newVals);
                    onValuesChanged([...newVals]);
                    setText('')
                }
            }}}
        />
    </ChipInputWrapper>);
}

export default ChipInput;
