import styled from 'styled-components';
import colors from "../../styles/colors";

export const ChipInputWrapper = styled.div`
  min-width: 220px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 4px 12px;
  gap: 6px;
  border-radius: 6px;
  border: 1px solid ${colors.theme.galilDark};
  > input {
    border: none;
    outline: none;
    flex: 1;
  }
  position: relative;
  min-height: 36px;
  font-size: 0.9rem;
  color: ${colors.theme.galilDark};
  background-color: white;
  box-sizing: border-box;
  outline: 2px solid transparent;
  &:focus {
    outline: 2px solid ${colors.theme.galilGreen};
    border: 1px solid transparent;
  }
  .MuiSvgIcon-root {
    margin: 0 !important;
  }
`;
