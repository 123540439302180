import React, {useMemo} from 'react';
import {PdfWrapper} from "./styles";
import DocViewer, {DocViewerRenderers} from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";
import {ErrorBoundary} from '../error-boundary';
import {useTranslation} from "react-i18next";

interface Props {
    files: File[];
    selectedFile: number;
    onError: () => void;
}

const PdfViewer: React.FC<Props> = (props: Props) => {
    const {files, selectedFile, onError} = props;
    const { t } = useTranslation();

    const documents = useMemo(() => {
        return files?.map(file => ({
            uri: window.URL.createObjectURL(file),
            fileType: file.type.split('/')[2]
        })) || [];
    }, [files]);

    return (
        <PdfWrapper key="doc-viewer-wrapper">
            <ErrorBoundary key="doc-viewer-error-boundary" onError={onError} t={t}>
                <DocViewer
                    key={`doc-${selectedFile}`}
                    config={{
                        header: {
                            disableHeader: true,
                            disableFileName: true,
                            retainURLParams: false,
                        }
                    }}
                    activeDocument={selectedFile !== null ? documents[selectedFile] : undefined}
                    documents={documents}
                    pluginRenderers={DocViewerRenderers}
                />
            </ErrorBoundary>
        </PdfWrapper>)
}

export default PdfViewer;
